import Avatar from "@mui/material/Avatar";

import raz from "./raz.jpg";
import * as constants from "./constants";
import "./Message.css";

export function Message({ isRazielTyping, message }) {
  const isRaziel = isRazielTyping || message.user === constants.USERS.RAZIEL;
  const className = isRaziel ? "razielMessage" : "userMessage";

  return (
    <div className={`message ${className}`}>
      {isRaziel && <Avatar alt="raz" src={raz} />}
      <span className="messageText">
        {isRazielTyping ? <Typing /> : message.text}
      </span>
    </div>
  );
}

function Typing() {
  return <div className="loading"></div>;
}
