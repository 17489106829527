import { useEffect, useState } from "react";

import { MessageInput } from "./MessageInput";
import { Messages } from "./Messages";
import * as constants from "./constants";
import "./AskRaziel.css";

export function AskRaziel() {
  const [chatHistory, setChatHistory] = useState([]);
  const [isRazielTyping, setIsRazielTyping] = useState(false);

  useEffect(() => {
    setIsRazielTyping(true);
    setTimeout(() => {
      setIsRazielTyping(false);
      setChatHistory([createRazielMessage("What do you want")]);
    }, 2000);
  }, []);

  const respondToUser = (updatedHistory) => {
    setIsRazielTyping(true);
    setTimeout(() => {
      setIsRazielTyping(false);
      setChatHistory([
        ...updatedHistory,
        createRazielMessage(
          constants.RESPONSES[
            Math.floor(Math.random() * constants.RESPONSES.length)
          ]
        ),
      ]);
    }, 2000);
  };
  const handleUserMessage = (text) => {
    const updatedHistory = [...chatHistory, createUserMessage(text)];
    setChatHistory(updatedHistory);
    respondToUser(updatedHistory);
  };

  return (
    <div>
      <div className="askRazielMain">
        <div className="header">Go cowboys</div>
        <Messages messages={chatHistory} isRazielTyping={isRazielTyping} />
      </div>
      <MessageInput handleUserMessage={handleUserMessage} />
    </div>
  );
}

function createRazielMessage(text) {
  return {
    user: constants.USERS.RAZIEL,
    text,
  };
}

function createUserMessage(text) {
  return {
    user: constants.USERS.YOU,
    text,
  };
}
