import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";

export function IntroDialog({ isOpen, setIsOpen }) {
  return (
    <div>
      <Dialog open={isOpen} aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">
          Welcome to AskRaziel
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            AskRaziel is an experimental conversational AI service powered by
            Past Brain Raziel (PBR), which is a large language model trained on
            millions of conversations with the real Raziel.
            <br />
            <br />
            By entering you acknowledge that AskRaziel may give incorrect or
            inappropriate information and agree to the terms of service.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
