export const USERS = {
  RAZIEL: "RAZIEL",
  YOU: "YOU",
};

export const RESPONSES = [
  "Shut up",
  "Man shut up",
  "Shut the fuck up",
  "Man shut the fuck up",
  "Shove it up your ass",
  "Fuck you",
  "Grow up",
  "Grow the fuck up",
  "Man grow the fuck up",
  "Fuckin idiot",
];
