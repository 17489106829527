import { useState } from "react";

import { IntroDialog } from "./IntroDialog";
import { AskRaziel } from "./AskRaziel";

function App() {
  const [isDialogOpen, setIsDialogOpen] = useState(true);

  return (
    <div>
      {isDialogOpen ? (
        <IntroDialog isOpen={isDialogOpen} setIsOpen={setIsDialogOpen} />
      ) : (
        <AskRaziel />
      )}
    </div>
  );
}

export default App;
