import { useState } from "react";

import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Send from "@mui/icons-material/Send";
import "./MessageInput.css";

export function MessageInput({ handleUserMessage }) {
  const [userInput, setUserInput] = useState("");

  const handleUserInputChange = (event) => {
    setUserInput(event.target.value);
  };

  const onClick = () => {
    handleUserMessage(userInput);
    setUserInput("");
  };

  return (
    <div className="messageInputContainer">
      <FormControl fullWidth variant="filled" onSubmit={onClick}>
        <OutlinedInput
          placeholder="What's on your mind?"
          type={"text"}
          onChange={handleUserInputChange}
          value={userInput}
          onKeyDown={(e) => e.keyCode === 13 && userInput.length && onClick()}
          endAdornment={
            <InputAdornment position="end">
              <IconButton color="primary" edge="end" onClick={onClick}>
                <Send />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    </div>
  );
}
