import Stack from "@mui/material/Stack";

import { Message } from "./Message";

export function Messages({ messages, isRazielTyping }) {
  return (
    <div className="messagesContainer">
      <Stack direction="column" spacing={1}>
        {messages.map((message) => (
          <Message message={message} />
        ))}
        {isRazielTyping && <Message isRazielTyping />}
      </Stack>
    </div>
  );
}
